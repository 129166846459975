import React, { useState, useEffect } from "react";
import axios from "axios";
import Movie from "./Movie";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import "../styles/components/popularMovies.scss";
import Backdrop from "./Backdrop-accueil";
import { Audio } from 'react-loading-icons';
import { NavLink, useHistory } from "react-router-dom";

const PopularMovies = () => {

    const [background, setBackground] = useState([]);
    const [popular, setPopular] = useState([]);
    const [tendances, setTendances] = useState([]);
    const [mystère, setMystère] = useState([]);
    const [romance, setRomance] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        axios.get(`https://api.themoviedb.org/3/discover/movie?api_key=e23dffe283d6cc671f59f72d8548bd06&language=fr-FR&with_genres=10770&page=${page}`)
            .then((res) => {
                setBackground(res.data.results);
                setLoading(false);
            });
    }, [page]);
    useEffect(() => {
        setLoading(true);
        axios.get(`https://api.themoviedb.org/3/movie/popular?api_key=e23dffe283d6cc671f59f72d8548bd06&language=fr-FR&query=&page=${page}`)
            .then((res) => {
                setPopular(res.data.results);
                setLoading(false);
            });
    }, [page]);
    useEffect(() => {
        setLoading(true);
        axios.get(`https://api.themoviedb.org/3/discover/movie?api_key=e23dffe283d6cc671f59f72d8548bd06&language=fr-FR&with_genres=10751&page=${page}`)
            .then((res) => {
                setTendances(res.data.results);
                setLoading(false);
            });
    }, [page]);
    useEffect(() => {
        setLoading(true);
        axios.get(`https://api.themoviedb.org/3/discover/movie?api_key=e23dffe283d6cc671f59f72d8548bd06&language=fr-FR&with_genres=28&page=${page}`)
            .then((res) => {
                setMystère(res.data.results);
                setLoading(false);
            });
    }, [page]);
    useEffect(() => {
        setLoading(true);
        axios.get(`https://api.themoviedb.org/3/discover/movie?api_key=e23dffe283d6cc671f59f72d8548bd06&language=fr-FR&with_genres=35&page=${page}`)
            .then((res) => {
                setRomance(res.data.results);
                setLoading(false);
            });
    }, [page]);

    return (
        <div className="popularMovies">
            {loading ? (
                <div className="audio">
                    <Audio stroke="#98ff98" strokeOpacity={.125} speed={.75} />
                </div>
            ) : (
                <div>
                    <header className="header-accueil">
                        <div className="row">
                            <div className="col-fluid col-lg col-md col-sd">
                                <Splide
                                    options={{
                                        autoplay: true,
                                        type: "loop",
                                        drag: false,
                                        perPage: 1,
                                        interval: 5000,
                                        pauseOnHover: false,
                                        rewind: true,
                                        speed: 3000,
                                        pagination: false,
                                        arrows: false,
                                        updateOnMove: true,
                                    }}>
                                    {popular.map((p) => (
                                        <SplideSlide key={p.id}>
                                            <div
                                                className="splide__img"
                                                onClick={() =>
                                                    history.push("/movieDetails", { id: p.id })
                                                }>
                                                <img
                                                    src={`https://image.tmdb.org/t/p/w1280/${p.backdrop_path}`}
                                                    className="img-fluid"
                                                    alt={p.title}
                                                />
                                            </div>
                                        </SplideSlide>
                                    ))}
                                </Splide>
                            </div>
                        </div>
                    </header>

                    <div className="accueil-body">
                        <div className="accueil-container">
                            <div className="tendances-carrousel ">
                                <div className="d-flex">
                                    <h2>Tendances</h2>
                                </div>
                            </div>
                        </div>

                        <div className="tendances-carrousel">
                            <Splide
                                options={{
                                    type: "slide",
                                    perPage: 3,
                                    perMove: 1,
                                    gap: "15px",
                                    width: "min(1200px, 100% - 1150px)",
                                    type: "loop",
                                    breakpoints: {
                                        992: { perPage: 2 },
                                        480: { perPage: 1, rewind: true },
                                    },
                                }}>
                                {tendances.map((p) => (
                                    <SplideSlide key={p.id}>
                                        <div className="card2">
                                            <a
                                                onClick={() =>
                                                    history.push("/movieDetails", { id: p.id })
                                                }>
                                                <img
                                                    className="card__image"
                                                    src={`https://image.tmdb.org/t/p/original/${p.poster_path}`}
                                                    alt={`photo-film-${p.title}`}
                                                />
                                                <div className="card__content">
                                                    <h3 className="card__title">{p.title}</h3>
                                                </div>
                                            </a>
                                        </div>
                                    </SplideSlide>
                                ))}
                            </Splide>
                        </div>

                        <div className="accueil-container">
                            <div className="tendances-carrousel">
                                <h2>Action</h2>
                                <Splide
                                    options={{
                                        type: "slide",
                                        perPage: 3,
                                        perMove: 1,
                                        gap: "15px",
                                        width: "min(1200px, 100% - 1150px)",
                                        type: "loop",
                                        breakpoints: {
                                            992: { perPage: 2 },
                                            480: { perPage: 1, rewind: true },
                                        },
                                    }}>
                                    {mystère.map((p) => (
                                        <SplideSlide key={p.id}>
                                            <div className="card2">
                                                <a
                                                    onClick={() =>
                                                        history.push("/movieDetails", { id: p.id })
                                                    }>
                                                    <img
                                                        className="card__image"
                                                        src={`https://image.tmdb.org/t/p/original/${p.poster_path}`}
                                                        alt={`photo-film-${p.title}`}
                                                    />
                                                    <div className="card__content">
                                                        <h3 className="card__title">{p.title}</h3>
                                                    </div>
                                                </a>
                                            </div>
                                        </SplideSlide>
                                    ))}
                                </Splide>
                            </div>
                        </div>

                        <div className="accueil-container">
                            <div className="tendances-carrousel">
                                <h2>Comédie</h2>
                                <Splide
                                    options={{
                                        type: "slide",
                                        perPage: 3,
                                        perMove: 1,
                                        gap: "15px",
                                        width: "min(1200px, 100% - 1150px)",
                                        type: "loop",
                                        breakpoints: {
                                            992: { perPage: 2 },
                                            480: { perPage: 1, rewind: true },
                                        },
                                    }}>
                                    {popular.map((p) => (
                                        <SplideSlide key={p.id}>
                                            <div className="card2">
                                                <a
                                                    onClick={() =>
                                                        history.push("/movieDetails", { id: p.id })
                                                    }>
                                                    <img
                                                        className="card__image"
                                                        src={`https://image.tmdb.org/t/p/original/${p.poster_path}`}
                                                        alt={`photo-film-${p.title}`}
                                                    />
                                                    <div className="card__content">
                                                        <h3 className="card__title">{p.title}</h3>
                                                    </div>
                                                </a>
                                            </div>
                                        </SplideSlide>
                                    ))}
                                </Splide>
                            </div>
                        </div>

                        <div className="accueil-container">
                            <div className="tendances-carrousel">
                                <h2>Familial</h2>
                                <Splide
                                    options={{
                                        type: "slide",
                                        perPage: 3,
                                        perMove: 1,
                                        gap: "15px",
                                        width: "min(1200px, 100% - 1150px)",
                                        type: "loop",
                                        breakpoints: {
                                            992: { perPage: 2 },
                                            480: { perPage: 1, rewind: true },
                                        },
                                    }}>
                                    {romance.map((p) => (
                                        <SplideSlide key={p.id}>
                                            <div className="card2">
                                                <a
                                                    onClick={() =>
                                                        history.push("/movieDetails", { id: p.id })
                                                    }>
                                                    <img
                                                        className="card__image"
                                                        src={`https://image.tmdb.org/t/p/original/${p.poster_path}`}
                                                        alt={`photo-film-${p.title}`}
                                                    />
                                                    <div className="card__content">
                                                        <h3 className="card__title">{p.title}</h3>
                                                    </div>
                                                </a>
                                            </div>
                                        </SplideSlide>
                                    ))}
                                </Splide>
                            </div>
                        </div>


                    </div>
                </div>
            )}


        </div>


    )
}





export default PopularMovies;
import React from "react";
import CategoriesMovies from "../components/CategoriesMovies";
import Header from '../components/header';
import Footer from "../components/footer";

const Categories = (props) => {

    const id = props.location.state?.id;
    const name = props.location.state?.name;
    return (
        <div>
            <Header />
            <CategoriesMovies idGenre={id} nameGenre={name} />
            <Footer />

        </div>
    )
}

export default Categories;
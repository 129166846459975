import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from '../components/header';
import Footer from "../components/footer";
import { useHistory } from "react-router-dom";
import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MovieDetails = (props) => {
    const id = props.location.state.id;
    const API_KEY = 'a67b57849deb687f2cd49d7a8298b366';
    const ytBaseUrl = 'https://www.youtube.com/embed/';
    const history = useHistory();

    const [detail, setDetail] = useState({});
    const [genres, setGenres] = useState([]);
    const [credits, setCredits] = useState([]);
    const [bannonce, setBannonce] = useState(null);
    const [show, setShow] = useState(false);

    const URL = `https://api.themoviedb.org/3/movie/${id}?api_key=${API_KEY}&language=fr-FR`;
    const URL_CREDITS = `https://api.themoviedb.org/3/movie/${id}/credits?api_key=${API_KEY}&language=fr-FR`;
    const URL_VIDEOS = `https://api.themoviedb.org/3/movie/${id}/videos?api_key=${API_KEY}`;

    useEffect(() => {
        axios.get(URL)
            .then((res) => {
                setDetail(res.data);
                setGenres(res.data.genres);
            })
            .catch((error) => {
                console.error('Error fetching movie details:', error);
            });

        axios.get(URL_CREDITS)
            .then((res) => {
                setCredits(res.data.cast);
            })
            .catch((error) => {
                console.error('Error fetching credits:', error);
            });

        axios.get(URL_VIDEOS)
            .then((res) => {
                if (res.data.results.length > 0) {
                    setBannonce(res.data.results[0]);
                }
            })
            .catch((error) => {
                console.error('Error fetching trailers:', error);
            });
    }, [URL, URL_CREDITS, URL_VIDEOS]);

    useEffect(() => {
        if (credits.length > 0) {
            new Splide('.splide', {
                type: 'loop',
                perPage: 3,
                perMove: 1,
            }).mount();
        }
    }, [credits]);

    useEffect(() => {
        if (detail.vote_average) {
            const percentage = Math.round(detail.vote_average * 10);
            const progressBar = document.querySelector('.ProgressBar-circle');

            if (progressBar) {
                const radius = progressBar.r.baseVal.value;
                const circumference = radius * 2 * Math.PI;

                progressBar.style.strokeDasharray = `${circumference}`;
                progressBar.style.strokeDashoffset = `${circumference}`;

                setTimeout(() => {
                    progressBar.style.strokeDashoffset = `${circumference - (percentage / 100) * circumference}`;
                }, 100);
            }
        }
    }, [detail.vote_average]);

    const backdropUrl = detail.backdrop_path ? `http://image.tmdb.org/t/p/original${detail.backdrop_path}` : '';

    return (
        <React.Fragment>
            <Header />
            <div className="section1-ff">
                <div
                    className="bg"
                    style={{ backgroundImage: `url(${backdropUrl})` }}>
                </div>

                <div className="container-film">
                    <div className="row">
                        <div className="cover-ff d-flex justify-content-center col-lg-6 col-xl-5">
                            <img src={`http://image.tmdb.org/t/p/original${detail.poster_path}`} alt="poster" />
                        </div>
                        <div className="words-ff col-md-12 col-lg-6 col-xl-7">
                            <div className="titre d-flex">
                                <h1>{detail.title}</h1>
                                <h2 className="année">({new Date(detail.release_date).getFullYear()})</h2>
                            </div>
                            <div className="sous-titre d-flex">
                                <div className="date">
                                    {new Date(detail.release_date).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                </div>
                                <div className="bull"> &#8226; </div>
                                {genres.map((g) => (
                                    <div
                                        className="catégories-ff"
                                        key={g.id}
                                        onClick={() => history.push('/categories', { id: g.id, name: g.name })}
                                    >
                                        {g.name}
                                    </div>
                                ))}
                                <div className="bull"> &#8226; </div>
                                <div className="heure">{Math.floor(detail.runtime / 60)}h{detail.runtime % 60}min</div>
                            </div>
                            <div className="ProgressBar ProgressBar--animateAll" data-progress={Math.round(detail.vote_average * 10)}>
                                <svg className="ProgressBar-contentCircle" viewBox="0 0 120 120">
                                    <circle transform="rotate(-90, 60, 60)" className="ProgressBar-background" cx="60" cy="60" r="50" />
                                    <circle
                                        transform="rotate(-90, 60, 60)"
                                        className="ProgressBar-circle"
                                        cx="60"
                                        cy="60"
                                        r="50"
                                        style={{ strokeDashoffset: 314 - (314 * Math.round(detail.vote_average * 10)) / 100 }}
                                    />
                                    <text x="50%" y="50%" textAnchor="middle" dy=".3em" className="ProgressBar-percentage">
                                        {Math.round(detail.vote_average * 10)}%
                                    </text>
                                </svg>
                            </div>
                            <div className="like">des utilisateurs <br /> aiment ce film</div>
                            <div className="résumé">{detail.tagline}</div>
                            <div className="synopsis">{detail.overview}</div>
                            <div className="bande-annonce mt-4">
                                {bannonce && (
                                    <a>
                                        <button onClick={() => setShow(true)}>
                                            Bande annonce <FontAwesomeIcon icon={faPlay} />
                                        </button>
                                    </a>
                                )}
                                {show && <iframe
                                    width="915"
                                    height="515"
                                    src={`${ytBaseUrl}${bannonce.key}`}
                                    frameBorder="0"
                                    allowFullScreen="true"
                                ></iframe>
                                }
                                {show && <a>
                                    <button onClick={() => setShow(false)}>Fermer <i className="fa-solid fa-play"></i></button>
                                </a>
                                }
                                <div className="titre-origine d-flex">
                                    <div className="origine-bleu">Titre d'origine:</div>
                                    <div className="origine-blanc">{detail.original_title}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section2-ff">
                <div className="all">
                    <div className="splide__wrapper">
                        <div className="splide" role="group" aria-label="Splide Basic HTML Example">
                            <div className="splide__track">
                                <ul className="splide__list">
                                    {credits.slice(0, 6).map((c) => (
                                        <li className="splide__slide" key={c.id}>
                                            <div className="cards">
                                                <div className="card">
                                                    <a onClick={() => history.push('/actorDetails', { id: c.id })}>
                                                        <div className="card__content">
                                                            <p className="name">{c.name}</p>
                                                            <p>{c.character}</p>
                                                            <div className="card__content-bg"></div>
                                                            {c.profile_path ? (
                                                                <img className="card__image" src={`http://image.tmdb.org/t/p/w500${c.profile_path}`} alt={c.name} />
                                                            ) : (
                                                                <p>No Image Available</p>
                                                            )}
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
}

export default MovieDetails;

// src/pages/SearchMovie.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaSearch } from 'react-icons/fa';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { useHistory } from 'react-router-dom';

const SearchMovie = () => {
    const [query, setQuery] = useState('');
    const [movies, setMovies] = useState([]);
    const [carouselImages, setCarouselImages] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const history = useHistory(); 

    useEffect(() => {
        const fetchCarouselImages = async () => {
            try {
                const response = await axios.get('https://api.themoviedb.org/3/movie/popular', {
                    params: {
                        api_key: 'e23dffe283d6cc671f59f72d8548bd06',
                    },
                });
                setCarouselImages(response.data.results.slice(0, 5));
            } catch (error) {
                console.error("Erreur lors de la récupération des images du carrousel", error);
            }
        };

        fetchCarouselImages();
    }, []);

    const handleSearch = async (e) => {
        e.preventDefault();
        setIsSearching(true);
        try {
            const response = await axios.get('https://api.themoviedb.org/3/search/movie', {
                params: {
                    api_key: 'e23dffe283d6cc671f59f72d8548bd06',
                    query: query,
                },
            });
            setMovies(response.data.results);
        } catch (error) {
            console.error("Erreur lors de la recherche des films", error);
        }
    };

    return (
        <div className="section-all">
            {!isSearching && (
                <div className="carrousel-recherche">
                    <Splide
                        id="splide1"
                        className="splide"
                        options={{
                            type: 'loop',
                            autoplay: true,
                            interval: 3000,
                            perPage: 1,
                            width: '100%',
                            height: 'auto',
                        }}
                    >
                        {carouselImages.map((movie) => (
                            <SplideSlide key={movie.id}>
                                <div className="image-r splide__img">
                                    <img
                                        src={`https://image.tmdb.org/t/p/original/${movie.backdrop_path}`}
                                        alt={movie.title}
                                    />
                                </div>
                            </SplideSlide>
                        ))}
                    </Splide>

                    <div className="container-r">
                        <form className="search-container" onSubmit={handleSearch}>
                            <div className="search-top">
                                <input
                                    type="text"
                                    id="search-bar"
                                    placeholder="Rechercher"
                                    value={query}
                                    onChange={(e) => setQuery(e.target.value)}
                                />
                                <button type="submit">
                                    <FaSearch />
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {isSearching && (
                <div className="search-results">
                    <form className="search-container-rf" onSubmit={handleSearch}>
                        <div className="search-top">
                            <input
                                type="text"
                                id="search-bar-rf"
                                placeholder="Rechercher"
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                            />
                            <button type="submit">
                                <FaSearch />
                            </button>
                        </div>
                    </form>

                    <div className="section1-rf">
                        <div className="page_wrapper">
                            {movies.length > 0 ? (
                                movies.map((movie) => (
                                    <div key={movie.id} className="card-style-1">
                                        <a
                                            onClick={() =>
                                                history.push("/movieDetails", { id: movie.id })
                                            }>
                                            <div className="image-rf">
                                                <img
                                                    src={`https://image.tmdb.org/t/p/w500/${movie.poster_path}`}
                                                    alt={movie.title}
                                                />
                                            </div>
                                            <div className="content-rf">
                                                <p>{movie.title}</p>
                                            </div>
                                        </a>
                                    </div>
                                ))
                            ) : (
                                <p>No movies found</p>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SearchMovie;

import React from "react";
import Header from '../components/header';
import Footer from "../components/footer";
import PopularMovies from "../components/PopularMovies";

const Popular = () => {

    return (
        <div>
            <Header/>
            <PopularMovies />
            <Footer/>
        </div>
    )
}

export default Popular;
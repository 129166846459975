import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const CategoriesMovies = () => {
    const [movies, setMovies] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const history = useHistory();
    const [isFrameVisible, setIsFrameVisible] = useState(false);

    useEffect(() => {
        if (selectedCategory !== null) {
            const fetchMovies = async () => {
                try {
                    const response = await axios.get(`https://api.themoviedb.org/3/discover/movie`, {
                        params: {
                            api_key: 'e23dffe283d6cc671f59f72d8548bd06',
                            with_genres: selectedCategory
                        }
                    });
                    setMovies(response.data.results);
                } catch (error) {
                    console.error('Erreur lors de la récupération des films :', error);
                }
            };
            fetchMovies();
        }
    }, [selectedCategory]);

    const handleCategoryChange = (event) => {
        setSelectedCategory(Number(event.target.value));
    };

    const categories = [
        { id: 28, name: 'Action' },
        { id: 12, name: 'Aventure' },
        { id: 16, name: 'Animation' },
        { id: 35, name: 'Comédie' },
        { id: 80, name: 'Crime' },
        { id: 99, name: 'Documentaire' },
        { id: 18, name: 'Drame' },
        { id: 10751, name: 'Familial' },
        { id: 14, name: 'Fantastique' },
        { id: 36, name: 'Histoire' },
        { id: 27, name: 'Horreur' },
        { id: 10402, name: 'Musique' },
        { id: 9648, name: 'Mystère' },
        { id: 10749, name: 'Romance' },
        { id: 878, name: 'Science-Fiction' },
        { id: 10770, name: 'Téléfilm' },
        { id: 53, name: 'Thriller' },
        { id: 10752, name: 'Guerre' },
        { id: 37, name: 'Western' }
    ];

    useEffect(() => {
        const button = document.getElementById('filtre-button');
        const frame = document.getElementById('categories-frame');

        const toggleFrame = (event) => {
            event.preventDefault();
            setIsFrameVisible(!isFrameVisible);
        };

        if (button) {
            button.addEventListener('click', toggleFrame);
        }

        return () => {
            if (button) {
                button.removeEventListener('click', toggleFrame);
            }
        };
    }, [isFrameVisible]);

    return (
        <div className="section1-cf">
            <div className="filtre-responsive">
                <a href="#" id="filtre-button">Filtre</a>
            </div>

            <div className={`frame ${isFrameVisible ? 'show' : ''}`} id="categories-frame">
                {categories.map(category => (
                    <a key={category.id} href="#" onClick={() => setSelectedCategory(category.id)}>
                        {category.name}
                    </a>
                ))}
            </div>

            <div className="d-flex">
                <form>
                    {categories.map(category => (
                        <div key={category.id}>
                            <input
                                id={`radio-${category.id}`}
                                className="radio-custom"
                                name="radio-group"
                                type="radio"
                                checked={selectedCategory === category.id}
                                onChange={handleCategoryChange}
                                value={category.id}
                            />
                            <label htmlFor={`radio-${category.id}`} className="radio-custom-label">
                                {category.name}
                            </label>
                        </div>
                    ))}
                </form>

                <div className="section2-cf">
                    <h1>Catégories</h1>
                    {selectedCategory === null ? (
                        <h4>Veuillez choisir une catégorie</h4>
                    ) : (
                        <>
                            <h2>{categories.find(c => c.id === selectedCategory)?.name}</h2>
                            <div className="page_wrapper">
                                {movies.map(movie => (
                                    <div className="card-style-1" key={movie.id}>
                                        <a
                                            onClick={() =>
                                                history.push("/movieDetails", { id: movie.id })
                                            }>
                                            <div className="image-rf">
                                                <img
                                                    src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                                                    alt={movie.title}
                                                />
                                            </div>
                                            <div className="content-rf">
                                                <p>{movie.title}</p>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CategoriesMovies;

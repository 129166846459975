import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Header from '../components/header';
import Footer from "../components/footer";

const ActorDetails = (props) => {
    const id = props.location.state.id;
    const API_KEY = 'a67b57849deb687f2cd49d7a8298b366';
    const URL = `https://api.themoviedb.org/3/person/${id}?api_key=${API_KEY}&language=fr-FR`;

    const [detail, setDetail] = useState({});
    const [knownFor, setKnownFor] = useState([]);
    const history = useHistory();

    useEffect(() => {
        axios.get(URL)
            .then((res) => {
                setDetail(res.data);
                return axios.get(`https://api.themoviedb.org/3/person/${id}/movie_credits?api_key=${API_KEY}&language=fr-FR`);
            })
            .then((res) => {
                setKnownFor(res.data.cast.slice(0, 5));
            })
            .catch((error) => console.error('Error fetching actor details:', error));
    }, [URL, id]);

    return (
        <React.Fragment>
            <Header />
            <div className="section1-fa">
                <div className="container-acteur">
                    <div className="row">
                        <div className="cover-fa col-lg-3">
                            <div className="img-acteur d-flex justify-content-center">
                                <img
                                    src={`http://image.tmdb.org/t/p/original${detail.profile_path}`}
                                    alt={detail.name}
                                />
                            </div>
                            <div className="détails-fa text-center">
                                <h4>Célèbre pour</h4>
                                <p>{detail.known_for_department}</p>
                                <h4>Apparition connues</h4>
                                <p>{knownFor.length}</p>
                                <h4>Genre</h4>
                                <p>{detail.gender === 1 ? "Femme" : "Homme"}</p>
                                <h4>Date de naissance</h4>
                                <p>{detail.birthday} ({new Date().getFullYear() - new Date(detail.birthday).getFullYear()} ans)</p>
                                <h4>Lieu de naissance</h4>
                                <p>{detail.place_of_birth}</p>
                            </div>
                        </div>
                        <div className="words-fa col-lg-9">
                            <div className="titre-fa">
                                <h1>{detail.name}</h1>
                            </div>
                            <div className="résumé-fa">Biographie</div>
                            <div className="biographie-fa">
                                {detail.biography ? detail.biography : "Biographie non disponible"}
                            </div>
                            <div className="célèbre-fa">Célèbre pour</div>
                            <div className="row films-fa">
                                {knownFor.map((film) => (
                                    <div className="col-6 col-md-4 col-lg-2 me-lg-3" key={film.id}>
                                        <a
                                            onClick={() => history.push(`/movieDetails`, { id: film.id })}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <img
                                                src={`http://image.tmdb.org/t/p/w500${film.poster_path}`}
                                                alt={film.title}
                                            />
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
}

export default ActorDetails;

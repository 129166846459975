
import React from 'react';
import '../styles/components/footer.scss';

const Footer = () => {
  return (
    <div class="footer">
    <div class="content-wrapper">
        <div class="d-flex justify-content-center">
            <i class="fa-regular fa-copyright"></i>
            <p>2024 LumaWave. Tous droits réservés</p>
        </div>
        <div class="d-flex justify-content-end tmdb-logo">
            <img src="logo-tmdb.svg" alt="TMDB Logo" />
        </div>
    </div>
</div>
  );
};

export default Footer;

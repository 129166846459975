import React, { useState } from 'react';
import { FaSearch, FaList, FaUser, FaTimes } from 'react-icons/fa';
import { NavLink } from "react-router-dom";
import '../styles/components/header.scss';

const App = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
    document.body.classList.toggle('nav-active', !isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
    document.body.classList.remove('nav-active');
  };

  return (
    <div className="section1-menu">
      <div className="fixed-top">
        <div className="menu">
          <div className="menu-logo">
            <NavLink exact to="/" className="menu-item" activeClassName="nav-active">
              <img src="logo.svg" alt="Logo" className="logo-image" />
            </NavLink>
          </div>
          <div className="menu-items">
            <NavLink exact to="/search" className="menu-item" activeClassName="nav-active">
              <FaSearch className="icon" />
              <span className="text">Recherche</span>
            </NavLink>

            <NavLink exact to="/categories" className="menu-item" activeClassName="nav-active">
              <FaList className="icon" />
              <span className="text">Catégories</span>
            </NavLink>

            <NavLink exact to="/Apropos" className="menu-item" activeClassName="nav-active">
              <FaUser className="icon" />
              <span className="text">À propos</span>
            </NavLink>
          </div>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className="menu-icon__line"></div>
          <div className="menu-icon__line menu-icon__line-left"></div>
          <div className="menu-icon__line menu-icon__line-right"></div>
        </div>
        {isMenuOpen && (
          <nav className="nav">
            <div className="nav__content">
              <div className="menu-logo">
                <NavLink exact to="/" className="menu-item" activeClassName="nav-active">
                  <img src="logo.svg" alt="Logo" className="logo-image-nav" />
                </NavLink>
              </div>
              <div className="menu-close" onClick={closeMenu}>
                <FaTimes />
              </div>
              <ul className="nav__list">
                <li className="nav__list-item">
                  <NavLink exact to="/search" onClick={closeMenu}>
                    Recherche
                  </NavLink>
                  <div className="arrow-menu">&gt;</div>
                </li>

                <li className="nav__list-item">
                  <NavLink exact to="/categories" onClick={closeMenu}>
                    Catégories
                  </NavLink>
                  <div className="arrow-menu">&gt;</div>
                </li>

                <li className="nav__list-item">
                  <NavLink exact to="/Apropos" onClick={closeMenu}>
                    À propos
                  </NavLink>
                  <div className="arrow-menu">&gt;</div>
                </li>

              </ul>
            </div>
          </nav>
        )}
      </div>
    </div>
  );
};

export default App;


import React from "react";
import Header from '../components/header';
import Footer from "../components/footer";
import SearchMovie from "../components/SearchMovie";

const Search = () => {
    return (
        <div>
            <Header />
            <SearchMovie />
            <Footer />
        </div>
    )
}

export default Search;
import React from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import "../styles/components/aPropos.scss";


const APropos = () => {
    return (

        <div class="section-all-ap">

            <Header />

            <div class="section1-ap">
                <h1>LumaWave</h1>
                <p>LumaWave est une nouveauté dans le monde du streaming. Créée en 2024 par Coralie et Axel, la plateforme se distingue par une offre variée de contenus et une interface intuitive. Grâce à des recommandations personnalisées et à un soutien actif pour les créateurs de contenu, LumaWave propose une expérience enrichissante. Multilingue et inclusive, la plateforme se veut accessible à tous, offrant des œuvres du monde entier. En peu de temps, LumaWave est devenue une référence incontournable pour les amateurs de streaming.</p>
            </div>

            <div class="section2-ap">
                <h1>L'équipe</h1>
                <div class="row-1 row">
                    <div class="col-6 d-flex justify-content-center">
                        <div class="row">
                            <div class="col-12">
                                <h2>Coralie Gilbert</h2>
                            </div>
                            <div class="col-12">
                                <p>Dans la création de LumaWave, Coralie s'est occupée de l'image de marque, comprenant le logo, les couleurs et le design. Elle a également pris en charge la conception des maquettes du site et leur intégration.</p>
                                <p>Coralie a travaillé méticuleusement pour créer une identité visuelle cohérente et attrayante. Elle a conçu un logo distinctif et sélectionné une palette de couleurs qui reflète l'esprit innovant et dynamique de LumaWave. En s'occupant de la création des maquettes, elle a veillé à ce que l'interface utilisateur soit intuitive et agréable, facilitant ainsi la navigation pour les utilisateurs.</p>
                                <p>De plus, Coralie a supervisé l'intégration de ces maquettes, s'assurant que le site final soit fidèle à sa vision initiale. Son attention aux détails et son expertise en design ont joué un rôle crucial dans l'établissement de LumaWave comme une plateforme de streaming à la fois esthétique et fonctionnelle.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-6 d-flex justify-content-center">
                        <div class="row">
                            <div class="col-12">
                                <h2>Axel Guerin</h2>
                            </div>
                            <div class="col-12">
                                <p>Dans la création de LumaWave, Axel s'est concentré sur la programmation, assurant la structuration du site et le développement de ses fonctionnalités. Il a mis en œuvre les aspects techniques nécessaires pour rendre l'interface utilisateur intuitive et fonctionnelle, facilitant ainsi la navigation pour les utilisateurs.</p>
                                <p>Axel a supervisé le développement des fonctionnalités clés de la plateforme, telles que les recommandations personnalisées et les outils interactifs pour les créateurs de contenu. Son savoir-faire en programmation a été essentiel pour garantir que LumaWave offre une expérience utilisateur optimale et immersive.</p>
                                <p>Grâce à son engagement et à sa maîtrise de la programmation, Axel a joué un rôle crucial dans l'établissement de LumaWave comme une plateforme de streaming de premier plan, répondant aux attentes élevées des utilisateurs en matière de qualité et de performance technique.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default APropos;